import styled, { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { darkTheme, lightTheme } from "./utils/Theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import New from "./pages/New";
import Popular from "./pages/Popular";
import Catalog from "./pages/Catalog";
import Video from "./pages/Video";
import Contact from "./pages/Contact";
import Faqs from "./pages/Faqs";
import Info from "./pages/Info";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Search from "./pages/Search";
import { useSelector } from "react-redux";
import useLocalStorage from "./redux/hooks/useLocalStorage";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
`;

const Main = styled.div`
  flex: 7;
  background-color: ${({ theme }) => theme.bg};
`;

const Wrapper = styled.div`
  padding: 30px 40px;
`;


function App() {
  const [darkMode, setDarkMode] = useLocalStorage("darkMode", false);

  const { currentUser } = useSelector((state) => state.user);

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container>
        <BrowserRouter>
          <Sidebar darkMode={darkMode} />
          <Main>
            <Header darkMode={darkMode} setDarkMode={setDarkMode} />
            <Wrapper>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="trend" element={<Popular title={t('popular')} />} />
                  <Route path="new" element={<New title={t('new')} />} />
                  <Route path="movies" element={<Catalog type="movies" title={t('movies')} />} />
                  <Route path="kmovies" element={<Catalog type="kmovies" title={t('korean_movies')} />} />
                  <Route path="fmovies" element={<Catalog type="fmovies" title={t('foreign_movies')} />} />
                  <Route path="tvshows" element={<Catalog type="tvshows" title={t('tv_shows')} />} />
                  <Route path="varietyshows" element={<Catalog type="varietyshows" title={t('variety_shows')} />} />
                  <Route path="dramas" element={<Catalog type="dramas" title={t('dramas')} />} />
                  <Route path="documentaries" element={<Catalog type="documentaries" title={t('documentaries')} />} />
                  <Route path="news" element={<Catalog type="news" title={t('news')} />} />
                  <Route path="oldshows" element={<Catalog type="oldshows" title={t('old_tv_shows')} />} />
                  <Route path="olddramas" element={<Catalog type="olddramas" title={t('old_dramas')} />} />
                  <Route path="oldvarietyshows" element={<Catalog type="oldvarietyshows" title={t('old_variety_shows')} />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="faqs" element={<Faqs />} />
                  <Route path="about" element={<Info type="about" title={t('about')} />} />
                  <Route path="privacy" element={<Info type="privacy" title={t('privacy')} />} />
                  <Route path="tos" element={<Info type="tos" title={t('tos')} />} />
                  <Route path="ads" element={<Info type="ads" title={t('ads')} />} />
                  <Route path="search" element={<Search />} />
                  <Route
                    path="register"
                    element={currentUser ? <Home /> : <Register />}
                  />
                  <Route
                    path="login"
                    element={currentUser ? <Home /> : <Login />}
                  />
                  <Route path="video" element={<Video />} />
                </Route>
              </Routes>
            </Wrapper>
          </Main>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
