import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { registerFailure, registerStart, registerSuccess } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  color: ${({ theme }) => theme.text};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bgLighter};
  border: 1px solid ${({ theme }) => theme.soft};
  padding: 20px 50px;
  gap: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  width: 100%;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.textSoft};
`;

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleRegister = async (e) => {
    e.preventDefault();
    dispatch(registerStart());
    try {
      const res = await axios.post("/auth/register", { name, email, password });
      dispatch(registerSuccess(res.data));
      navigate("/")
    } catch (err) {
      dispatch(registerFailure());
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>{t('register')}</Title>
        <Input
          placeholder={t('username')}
          onChange={(e) => setName(e.target.value)}
        />
        <Input placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
        <Input
          type="password"
          placeholder={t('password')}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleRegister}>{t('register')}</Button>
      </Wrapper>
    </Container>
  );
};

export default Register;