const us = 
    {
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "login": "Login",
        "register": "Register",
        "logout": "Logout",
        "subject": "Subject",
        "message": "Details",
        "name": "Full Name",
        "email": "Email",
        "username": "Username",
        "password": "Password",
        "profile": "Profile",
        "share": "Share",
        "views": "Views",
        "episode": "",
        "other_episodes": "Other Episodes",
        "season": "Season",
        "korean": "한국어",
        "english": "English",
        "search": "Search for anything",
        "home": "Home",
        "popular": "Popular",
        "new": "Most Recent",
        "movies": "Movies",
        "korean_movies": "Korean Movies",
        "foreign_movies": "Foreign Movies",
        "tv_shows": "TV Shows",
        "variety_shows": "Variety Shows",
        "dramas": "Dramas",
        "documentaries": "Documentaries",
        "news": "News",
        "old_tv_shows": "Old Shows",
        "old_dramas": "Old Dramas",
        "old_variety_shows": "Old Variety Shows",
        "community": "Community",
        "contact": "Contact Us",
        "contact_message": "Visit our FAQs page and find answers to your question instantly.",
        "faqs": "FAQs",
        "faq_message": "If you cannot find the answer to your question in our FAQs, please contact us directly.",
        "about": "About Us",
        "privacy": "Privacy",
        "tos": "Terms",
        "ads": "Advertisements",
        "sort_popular": "Popular",
        "sort_new": "Newest",
        "sort_alphabetical": "Alphabetical",
        "view_all": "View All",
        "popular_dramas": "Popular Dramas",
        "popular_tv_shows": "Popular TV Shows",
        "popular_movies": "Popular Movies",
        "newest_dramas": "Most Recent Dramas",
        "newest_tv_shows": "Most Recent TV Shows",
        "newest_movies": "Most Recent Movies",
        "home_section1": "View the Latest Dramas",
        "home_section2": "Check out Old Shows",
        "home_section3": "Fun and Exciting Movies",
        "name_test": "Extraordinary Attorney Woo",
        "description_test": "Woo Young-Woo (Park Eun-Bin) is extremely smart and she also has autism spectrum disorder. She never forgets what she sees, but she lacks in social skills and empathy. Woo Young-Woo begins to work as a trainee lawyer at a large law firm. While working there, she faces prejudice and irrationality against her, but she solves cases with her own unique perspective and grows as a lawyer."
    }

export default us;