import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { persistor, store } from './redux/store';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import App from './App';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import English from "./assets/locales/us";
import Korean from "./assets/locales/kr";

const root = ReactDOM.createRoot(document.getElementById('root'));

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['US', 'KR'],
    resources: {
      US: { translation: English },
      KR: { translation: Korean },
    },
    fallbackLng: 'US',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
      cookieMinutes: 10 * 365 * 24 * 10,
    }
  })

const spinner = (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress />
  </Box>
)

root.render(
  <Suspense fallback={spinner}>
    <React.StrictMode>
      <Provider store= {store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      </Provider>
    </React.StrictMode>
  </Suspense>
);