import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import axios from "axios";

import { useTranslation } from 'react-i18next';

const Container = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textSoft};
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  margin: 5px 0px;
`;

const More = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Filter = styled.select`
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bg};
  border: 1px solid ${({ theme }) => theme.bg};
  font-weight: bold;
  padding: 5px 10px;
`;

const Option = styled.option`
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bg};
  border: 1px solid ${({ theme }) => theme.bg};
  font-weight: bold;
  padding: 5px 10px;
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.textSoft};
  margin: 5px 0px;
`;

const Catalog = ({type, title}) => {

  const { t } = useTranslation()

  return (
    <Container>
      <Info>
        <Title>{ title }</Title>
        <More>
          <Filter>
            <Option>{t('sort_popular')}</Option>
            <Option>{t('sort_new')}</Option>
            <Option>{t('sort_alphabetical')}</Option>
          </Filter>
        </More>
      </Info>
      <List>
        <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
        <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
        <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
      </List>
    </Container>
  )
}

export default Catalog