import React from "react";
import styled from "styled-components";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import EpisodeCard from "../components/EpisodeCard";
import VideoPlayer from "../components/VideoPlayer";

import { useTranslation } from 'react-i18next';

const Container = styled.div`
`;

const Content = styled.div`
  width: 100%;
`;

const VideoWrapper = styled.div`
  display: flex;
`;

const VideoContainer = styled.div`
  flex: 12;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const Hr = styled.hr`
  margin: 20px 0px;
  border: 0.5px solid ${({ theme }) => theme.soft};
`;

const Advertisement = styled.img`
  flex: 2;
  margin-left: 20px;
  max-height: 720px;
`;

const Description = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  font-weight: 200;
  line-height: 2;
`;

const Additional = styled.div`
  margin-top: 40px;
`;

const Episodes = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
`;

const EpisodeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const More = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.textSoft};
  font-weight: bold;
  gap: 5px;
`;

const Filter = styled.select`
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bg};
  border: 1px solid ${({ theme }) => theme.bgLighter};
  font-weight: bold;
  padding: 5px 10px;
  width: 120px;
`;

const Option = styled.option`
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bg};
  border: 1px solid ${({ theme }) => theme.bgLighter}
  font-weight: bold;
  padding: 5px 10px;
`;

const Video = () => {

  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <VideoWrapper>
          <VideoContainer>
            <iframe
              width="100%"
              height="720"
              src="https://www.youtube.com/embed/JQ2rukD8B8U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoContainer>
          <Advertisement src="https://st2.depositphotos.com/5544578/8976/v/950/depositphotos_89763874-stock-illustration-click-here-vertical-advertising-banner.jpg"/>
        </VideoWrapper>
        <Title>{t('name_test')} • 149{t('episode')}</Title>
        <Details>
          <Info>7,948,154 {t('views')} • 06/22/2022</Info>
          <Buttons>
            <Button>
              <ThumbUpOutlinedIcon /> 123
            </Button>
            <Button>
              <ThumbDownOffAltOutlinedIcon /> 22
            </Button>
            <Button>
              <ReplyOutlinedIcon /> {t('share')}
            </Button>
          </Buttons>
        </Details>
        <Hr />
        <Description>
          {t('description_test')}
        </Description>
        <Hr />
      </Content>
      <Additional>
        <EpisodeInfo>
          <Title>{t('other_episodes')}</Title>
          <More>
            <Filter>
              <Option>{t('season')} 1</Option>
              <Option>{t('season')} 2</Option>
            </Filter>
          </More>
        </EpisodeInfo>
        <Episodes>
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
          <EpisodeCard />
        </Episodes>
      </Additional>
    </Container>
  );
};

export default Video;