import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "./Footer";

import { useTranslation } from 'react-i18next';

import LogoDark from "../assets/logo-dark.png";
import LogoLight from "../assets/logo-light.png";

import HomeIcon from '@mui/icons-material/Home';
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import ForumIcon from '@mui/icons-material/Forum';


const Container = styled.div`
  background-color: ${({ theme }) => theme.bgLighter};
  height: 100vh;
  width: 240px;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  position: sticky;
  top: 0;
`;

const Wrapper = styled.div`
  padding: 0px 20px;
`;

const LogoImage = styled.img`
    max-height: 40px;
    width: 180px;
    margin: 15px 0px 30px 0px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 7.5px 0px;
  font-weight: bold;

  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }
`;

const Subitem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0px 5px 45px;

  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }
`;

const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.bg};
`;


const Sidebar = ({ darkMode }) => {

  const { t } = useTranslation()

  return (
    <Container>
        <Wrapper>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            {darkMode ? <LogoImage src={LogoDark} />: <LogoImage src={LogoLight} />}
          </Link>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}><Item><HomeIcon />{t('home')}</Item></Link>
          <Link to="trend" style={{ textDecoration: "none", color: "inherit" }}><Item><StarOutlineIcon />{t('popular')}</Item></Link>
          <Link to="new" style={{ textDecoration: "none", color: "inherit" }}><Item><ExploreOutlinedIcon />{t('new')}</Item></Link>
          <Hr />
          <Link to="movies" style={{ textDecoration: "none", color: "inherit" }}><Item><MovieIcon />{t('movies')}</Item></Link>
            <Link to="kmovies" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('korean_movies')}</Subitem></Link>
            <Link to="fmovies" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('foreign_movies')}</Subitem></Link>
          <Hr />
          <Link to="tvshows" style={{ textDecoration: "none", color: "inherit" }}><Item><LiveTvIcon />{t('tv_shows')}</Item></Link>
            <Link to="varietyshows" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('variety_shows')}</Subitem></Link>
            <Link to="dramas" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('dramas')}</Subitem></Link>
            <Link to="documentaries" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('documentaries')}</Subitem></Link>
            <Link to="news" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('news')}</Subitem></Link>
          <Hr />
            <Link to="oldshows" style={{ textDecoration: "none", color: "inherit" }}><Item><LocalMoviesIcon />{t('old_tv_shows')}</Item></Link>
            <Link to="olddramas" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('old_dramas')}</Subitem></Link>
            <Link to="oldvarietyshows" style={{ textDecoration: "none", color: "inherit" }}><Subitem>{t('old_variety_shows')}</Subitem></Link>
          <Hr />
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}><Item><ForumIcon />{t('community')}</Item></Link>
          <Link to="contact" style={{ textDecoration: "none", color: "inherit" }}><Item><FlagOutlinedIcon />{t('contact')}</Item></Link>
          <Link to="faqs" style={{ textDecoration: "none", color: "inherit" }}><Item><HelpOutlineOutlinedIcon />{t('faqs')}</Item></Link>
          <Hr />
          <Footer />
        </Wrapper>
    </Container>
  )
}

export default Sidebar