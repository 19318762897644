import React from 'react'
import styled from "styled-components";

import { useTranslation } from 'react-i18next';

const Container = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textSoft};
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  margin: 5px 0px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;


const Info = ({type, title}) => {
    const { t } = useTranslation()

    return (
        <Container>
            <Title>{ title }</Title>
            <Body>
               Placeholder
            </Body>
        </Container>
    )
}

export default Info