import React from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";
import ContactBanner from "../assets/contact-banner.png";

import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Container = styled.div`
    width: 80%;
`;

const Banner = styled.img`
    width: 100%;
`;

const Body = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.bgLighter};
    color: ${({ theme }) => theme.text};
`;

const Form = styled.form`
    display:flex;
    flex-direction:column;
    padding: 10px;
`;

const TextField = styled.input`
    width: 100%;
    box-sizing: border-box;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.bg};
    border: 1px solid ${({ theme }) => theme.bgLighter};
    display: inline-block;
    padding: 10px 15px;
    margin: 5px 0px;
`;

const TextBox = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.bg};
    border: 1px solid ${({ theme }) => theme.bgLighter};
    display: inline-block;
    padding: 10px 15px;
    margin: 5px 0px;
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    font-size: 18px;
    color: ${({ theme }) => theme.text};
    justify-content: center;
    margin: 40px 0px 30px 0px;
`;

const Faq = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Button = styled.button`
    border-radius: 3px;
    border: none;
    padding: 20px 80px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${({ theme }) => theme.soft};
    color: ${({ theme }) => theme.textSoft};
`;

const Contact = () => {

    const { t } = useTranslation();
  
    return (
        <Wrapper>
            <Container>
                <Banner src={ContactBanner} />
                <Body>
                    <Form>
                        <TextField placeholder={t('name')} />
                        <TextField placeholder={t('email')} />
                        <TextField placeholder={t('subject')} />
                        <TextBox placeholder={t('message')} rows={10} />
                        <Button type="submit">{t('contact')}</Button>
                    </Form>
                </Body>
                <Footer> 
                    {t('contact_message')}
                </Footer>
                <Faq>
                    <Link to={`/faqs`} style={{ textDecoration: "none" }}><Button>{t('faqs')}</Button></Link>
                </Faq>
            </Container>
        </Wrapper>
    )
}

export default Contact