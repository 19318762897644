const kr = 
    {
        "monday": "월요일",
        "tuesday": "화요일",
        "wednesday": "수요일",
        "thursday": "목요일",
        "friday": "금용일",
        "saturday": "토요일",
        "sunday": "일요일",
        "login": "로그인",
        "register": "회원가입",
        "logout": "로그아웃",
        "subject": "제목",
        "message": "내용",
        "name": "이름",
        "email": "이메일",
        "username": "아이디",
        "password": "패스워드",
        "profile": "회원정보",
        "share": "공유",
        "views": "조회수",
        "episode": "화",
        "other_episodes": "다른 에피소드",
        "season": "시즌",
        "korean": "한국어",
        "english": "English",
        "search": "검색어를 입력해주세요",
        "home": "홈",
        "popular": "인기작품",
        "new": "최신작품",
        "movies": "영화",
        "korean_movies": "한국영화",
        "foreign_movies": "외국영화",
        "tv_shows": "방송프로",
        "variety_shows": "예능/오락",
        "dramas": "드라마",
        "documentaries": "시사/다큐",
        "news": "뉴스",
        "old_tv_shows": "추억의 저장소",
        "old_dramas": "추억의 드라마",
        "old_variety_shows": "추억의 예능",
        "community": "커뮤니티",
        "contact": "문의하기",
        "contact_message": "자주 묻는 질문들은 고객센터에서 확인하세요.",
        "faqs": "고객센터",
        "faq_message": "FAQs에서 원하는 답변을 찾지 못하셨다면, 직접 문의해주세요.",
        "about": "회사소개",
        "privacy": "개인정보",
        "tos": "이용약관",
        "ads": "광고문의",
        "sort_popular": "인기순",
        "sort_new": "최신순",
        "sort_alphabetical": "가나다순",
        "view_all": "전체보기",
        "popular_dramas": "인기 있는 드라마",
        "popular_tv_shows": "인기 있는 방송프로",
        "popular_movies": "인기 있는 영화",
        "newest_dramas": "최근 나온 드라마",
        "newest_tv_shows": "최근 나온 방송프로",
        "newest_movies": "최근 나온 영화",
        "home_section1": "최신 드라마를 모두 감상해보세요",
        "home_section2": "그리운 추억의 예능",
        "home_section3": "알고보면 더욱 재밌는 영화",
        "name_test": "이상한 변호사 우영우",
        "description_test": "'자폐 스펙트럼 장애'를 가진 영우는 강점과 약점을 한 몸에 지닌 캐릭터다. 영우의 강점은 우리들 대부분이 범접할 수 없을 만큼 탁월하지만, 영우의 약점은 우리들 대부분이 깜짝 놀랄 만큼 취약하다. 164의 높은 IQ, 엄청난 양의 법조문과 판례를 정확하게 외우는 기억력, 선입견이나 감정에 사로잡히지 않는 자유로운 사고방식이 영우의 강점이다. 감각이 예민해 종종 불안해하고, 몸을 조화롭게 다루지 못해 걷기, 뛰기, 신발끈 묶기, 회전문 통과 등에 서툴다. 영우는 극도의 강함과 극도의 약함을 한몸에 지닌 인물이자 높은 IQ와 낮은 EQ의 결합체이며 우리들 대부분보다 우월한 동시에 우리들 대부분보다 열등한 존재다. 영우는 한마디로 흥미롭다."
    }

export default kr;