export const darkTheme = {
    bg:"#181818",
    bgLighter:"#202020",
    bgBlue: "#1976d2",
    text:"#ffffff",
    textSoft:"#aaaaaa",
    logoText: "#ffffff",
    soft:"#373737"
  }
  
  export const lightTheme = {
    bg:"#f9f9f9",
    bgLighter:"#ffffff",
    bgBlue: "#1976d2",
    text:"#000000",
    textSoft:"#606060",
    logoText: "#000000",
    soft:"#ebebeb"
  }