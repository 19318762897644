import React from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";
import FaqBanner from "../assets/faq-banner.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    width: 80%;
`;

const Banner = styled.img`
    width: 100%;
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    font-size: 18px;
    color: ${({ theme }) => theme.text};
    justify-content: center;
    margin: 40px 0px 30px 0px;
`;

const Contact = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const Button = styled.button`
    border-radius: 3px;
    border: none;
    padding: 20px 80px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${({ theme }) => theme.soft};
    color: ${({ theme }) => theme.textSoft};
`;

const Faqs = () => {

    const { t } = useTranslation();
  
    return (
        <Wrapper>
            <Container>
                <Banner src={FaqBanner} />
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 1: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 2: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 3: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 4: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 5: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>Question 6: XYZ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>Answer placeholder</Typography>
                    </AccordionDetails>
                </Accordion>
                <Footer> 
                    {t('faq_message')}
                </Footer>
                <Contact>
                    <Link to={`/contact`} style={{ textDecoration: "none" }}><Button>{t('contact')}</Button></Link>
                </Contact>
            </Container>
        </Wrapper>
    )
}

export default Faqs