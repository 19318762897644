import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Banner from "../components/Banner";
import Timetable from "../components/Timetable";
import axios from "axios";
import SlideshowIcon from '@mui/icons-material/Slideshow';

import { useTranslation } from 'react-i18next';

const Container = styled.div`

`;

const Section = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.textSoft};
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  margin: 5px 0px;
`;

const More = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.textSoft};
  font-weight: bold;
  gap: 5px;
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.textSoft};
  margin: 5px 0px;
`;


const Home = () => {

  const { t } = useTranslation()
  
  return (
    <Container>
        <Banner />
        <Timetable />
        <Section>
          <Info>
            <Title>{t('home_section1')}</Title>
            <More><SlideshowIcon />{t('view_all')}</More>
          </Info>
          <List>
            <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
          </List>
        </Section>
        <Section>
          <Info>
            <Title>{t('home_section2')}</Title>
            <More><SlideshowIcon />{t('view_all')}</More>
          </Info>
          <List>
            <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
          </List>
        </Section>
        <Section>
          <Info>
            <Title>{t('home_section3')}</Title>
            <More><SlideshowIcon />{t('view_all')}</More>
          </Info>
          <List>
            <Card /><Card /><Card /><Card /><Card /><Card /><Card /><Card />
          </List>
        </Section>
    </Container>
  );
};

export default Home;