import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutStart, logoutSuccess, logoutFailure } from "../redux/userSlice";

import i18next from 'i18next';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import ReactFlagsSelect from "react-flags-select";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";


const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bgLighter};
  height: 56px;
  z-index: 999;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0px 20px;
  position: relative;

  .menu-flags {
    margin-top: 5px;

    .menu-flags-button {
      border: 0px;
    }  

    > * {
      color: ${({ theme }) => theme.text};
      font-size: 14px;
      background-color: ${({ theme }) => theme.bgLighter};
      border: 0px solid ${({ theme }) => theme.soft};
    }
  }
`;

const Search = styled.div`
  @media (min-width: 1440px) {
    width: 40%;
  }
  @media (max-width: 1440px) {
    width: 30%;
  }
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text};
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  color: ${({ theme }) => theme.text};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;  
  width: 115px;
  margin-left: 10px;
  padding: 5px 15px;
  background-color: #3ea6ff;
  border: 1px solid #3ea6ff;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  gap: 5px;
`;

const Button2 = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: transparent;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const Header = ({ darkMode, setDarkMode }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const openProfile = Boolean(anchorEl);

  const { t } = useTranslation()
  const currentLanguageCode = cookies.get('i18next') || 'KR'
  let currentLanguage = currentLanguageCode

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logoutStart());
    try {
      const res = await axios.get("/auth/logout");
      dispatch(logoutSuccess(res.data));
      navigate("/")
    } catch (err) {
      dispatch(logoutFailure());
    }
  };

  const handleChange = (code) => {
    i18next.changeLanguage(code);
    currentLanguage = code;
  }

  return (
    <>
      <Container>
        <Wrapper>
          <Search>
            <Input
              placeholder={t('search')}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchOutlinedIcon onClick={()=>navigate(`/search?q=${search}`)}/>
          </Search>
          <Button2 onClick={() => setDarkMode(!darkMode)} >{darkMode ? <LightModeIcon /> : <DarkModeIcon />}</Button2>
          <ReactFlagsSelect
            className="menu-flags"
            selectButtonClassName="menu-flags-button"
            selected={currentLanguage}
            onSelect={handleChange}
            countries={["US", "KR"]}
            showSelectedLabel={false}
            showOptionLabel={true}
            customLabels={{"US": t('english'),"KR": t('korean')}}
            selectedSize={20}
            optionsSize={14}
            fullWidth={false}
          />
          {currentUser ? (
            <User>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openProfile ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>P</Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openProfile}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <Avatar /> {t('profile')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon >
                  {t('logout')}
                </MenuItem>
              </Menu>
            </User>
          ) : (
            <>
            <Link to="register" style={{ textDecoration: "none" }}>
            <Button>
              <AccountCircleOutlinedIcon />
              {t('register')}
            </Button>
          </Link>
            <Link to="login" style={{ textDecoration: "none" }}>
              <Button>
                <AccountCircleOutlinedIcon />
                {t('login')}
              </Button>
            </Link>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Header