import React from 'react'
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";

import { useTranslation } from 'react-i18next';

import TvIcon from '@mui/icons-material/Tv';
import VideocamIcon from '@mui/icons-material/Videocam';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MovieIcon from '@mui/icons-material/Movie';
import SlideshowIcon from '@mui/icons-material/Slideshow';

const Container = styled.div`
    background-color: ${({ theme }) => theme.bgLighter};
    margin-bottom: 40px;
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: ${({ theme }) => theme.textSoft};
  font-weight: bold;
  gap: 10px;
`;

const More = styled.button`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-size: 14px;
  border: 3px solid ${({ theme }) => theme.bgLight};
  border-radius: 3px;
  color: ${({ theme }) => theme.textSoft};
  background-color: transparent;
  cursor: pointer;
  padding: 5px 15px;
  margin-top: 10px;
  font-weight: bold;
  gap: 5px;

  &:hover {
    color: ${({ theme }) => theme.text};
  }
`;

const StyledTable = styled.div`
    table {
        width: 100%;
        border-spacing: 0;

        th {
            width: 25%;
            margin: 0;
            padding: 10px 10px 20px 10px;

            :last-child {
                border-right: 0;
            }
            
        }

        td {
            width: 25%;
            margin: 0;
            padding: 0.5rem;
            border-right: 1px solid ${({ theme }) => theme.bg};
            vertical-align: top;

            :last-child {
                border-right: 0;
            }
        }
    }

    ul {
        font-size: 14px;

        li {
            padding-bottom: 10px;
            color: ${({ theme }) => theme.text};
            list-style-type: none;
            text-decoration: none;

            :before {
                content: "\\00BB";
            }
        }

        li a {
            position: relative;
            left: 20px;
            color: ${({ theme }) => theme.text};
            text-decoration: none;

            &:hover {
                color: ${({ theme }) => theme.textSoft};
            }
        }
    }
`

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Timetable = () => {
    const current = new Date();
    const date = `${current.getMonth()+1}/${current.getDate()}`;
    const day = (current.getDay() + 6) % 7;
    const [value, setValue] = React.useState(day);
    const { t } = useTranslation()

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Container>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                >

                    <Tab label={`${t('monday')} (8/1)`} {...a11yProps(0)} />
                    <Tab label={`${t('tuesday')} (8/2)`} {...a11yProps(1)} />
                    <Tab label={`${t('wednesday')} (8/3)`} {...a11yProps(2)} />
                    <Tab label={`${t('thursday')} (8/4)`} {...a11yProps(3)} />
                    <Tab label={`${t('friday')} (8/5)`} {...a11yProps(4)} />
                    <Tab label={`${t('saturday')} (8/6)`} {...a11yProps(5)} />
                    <Tab label={`${t('sunday')} (8/7)`} {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <StyledTable>
                <table>
                    <thead>
                        <tr>
                            <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동상이몽 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>구해줘! 홈즈</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>개는 훌륭하다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>무엇이든 물어보세요</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>모란봉 클럽</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>노는언니 2</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StyledTable>
                <table>
                    <thead>
                        <tr>
                        <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>놀면 뭐하니?</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>아는형님</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>불후의 명곡 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>전지적 참견 시점</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>살림하는 남자들 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>쇼! 음악중심</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <StyledTable>
                <table>
                    <thead>
                        <tr>
                        <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>다시 쓰는 육아일기</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>뭉쳐야 산다 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>돌싱글즈 3</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>런닝맨</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>1박 2일 시즌 4</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>집사부일체</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <StyledTable>
                <table>
                    <thead>
                        <tr>
                        <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>놀면 뭐하니?</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>아는형님</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>불후의 명곡 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>전지적 참견 시점</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>살림하는 남자들 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>쇼! 음악중심</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={4}>
            <StyledTable>
                <table>
                    <thead>
                        <tr>
                        <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>놀면 뭐하니?</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>아는형님</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>불후의 명곡 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>전지적 참견 시점</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>살림하는 남자들 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>쇼! 음악중심</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <StyledTable>
                <table>
                    <thead>
                        <tr>
                        <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>놀면 뭐하니?</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>아는형님</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>불후의 명곡 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>전지적 참견 시점</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>살림하는 남자들 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>쇼! 음악중심</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>마녀는 살아있다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>여상육정</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>이상한 변호사 우영우</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>그것이 알고싶다</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>걸어서 세계속으로</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>동네 한 바퀴 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>극한직업</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>기적의 인생</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>생방송 심야토론</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
            <TabPanel value={value} index={6}>
            <StyledTable>
                <table>
                    <thead>
                        <tr>
                            <th><Title><TvIcon />{t('variety_shows')}</Title></th>
                            <th><Title><MovieIcon />{t('dramas')}</Title></th>
                            <th><Title><VideocamIcon />{t('documentaries')}</Title></th>
                            <th><Title><NewspaperIcon />{t('news')}</Title></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>다시 쓰는 육아일기</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>뭉쳐야 산다 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>돌싱글즈 3</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>런닝맨</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>1박 2일 시즌 4</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>집사부일체</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>현재는 아름다워</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>트레이서 시즌 2</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>연인</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>인생다큐</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>일요 특선 다큐멘터리</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>시사멘터리</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>루틴왕</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>탐사기획 스트레이트</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>영상앨범 산</Link></li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>JTBC 뉴스룸</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>MBC 뉴스데스크</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>KBS 뉴스 9</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>SBS 뉴스 8</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>글로벌 NOW</Link></li>
                                    <li><Link to={`/video`} style={{ textDecoration: "none" }}>YTN 24</Link></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <center>
                                    <Link to="new" style={{ textDecoration: "none" }}><More><SlideshowIcon />{t('view_all')}</More></Link>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </StyledTable>
            </TabPanel>
        </Container>
    )
}

export default Timetable