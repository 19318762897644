import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    width: 100%;
`;

const PageList = styled.nav`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  flex-wrap: wrap;
  padding-top: 10px;
`;

const Item = styled(Link)`
  font-size: 13px;
  padding: 5px 0;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  width:50%;
`;

const Copyright = styled.p`
  font-size: 11px;
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  margin-top:15px;
`;

const Footer = () => {

  const { t } = useTranslation();

  return (
    <Wrapper>
        <PageList>
            <Item to="/about" style={{ textDecoration: "none" }}>{t('about')}</Item>
            <Item to="/privacy" style={{ textDecoration: "none" }}>{t('privacy')}</Item>
            <Item to="/tos" style={{ textDecoration: "none" }}>{t('tos')}</Item>
            <Item to="/ads" style={{ textDecoration: "none" }}>{t('ads')}</Item>
        </PageList>
        <Copyright>© OGK Inc 2022. All Right Reserved</Copyright>
    </Wrapper>
  )
}

export default Footer