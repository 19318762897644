import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Banner1 from "../assets/banner.jpg";
import Banner2 from "../assets/banner.jpg";
import Banner3 from "../assets/banner.jpg";

const Banner = () => {
  return (
    <Carousel
        autoPlay={true}
        showThumbs={false}
    >
        <div>
            <img src={Banner1} alt="" />
        </div>
        <div>
            <img src={Banner2} alt="" />
        </div>
        <div>
            <img src={Banner3} alt="" />
        </div>
    </Carousel>
  )
}

export default Banner